import React from 'react';
import Container from '../../common/components/UI/Container';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';

import { ContactUsWrapper } from './contactUs.style';

const ContactUs = () => {

   const navigateToTest = () => {
    const currentUrlParams = new URLSearchParams( window.location.search );
    const baseUrl = 'https://quiz.sbit.in/sbit/survey/T97ywXFKJ9g';
    const newUrl = `${ baseUrl }?${ currentUrlParams.toString() }`;
    window.open( newUrl, '_blank' );
  };

  return (
    <ContactUsWrapper id="contact_section">
      <Container>
        <Box className="contactInner">
          <Box className="leftContent">
            <Heading as="h3" content="Start Shaping Your Future Today!" />
            <Heading
              as="h3"
              className="color2"
              content="Unlock Your Career Readiness Score, Traits, Recommendations, Certificate, and a Free Mentorship Session!"
            />
          </Box>
          <Button onClick={ navigateToTest } className="contactBtn" title="Download Your Free Report!" />
        </Box>
      </Container>
    </ContactUsWrapper>
  );
};

export default ContactUs;
